import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Dealerships = Loadable(lazy(() => import('./Dealerships')));
const Dealership = Loadable(lazy(() => import('./Dealership/Dealership')));
const NewDealership = Loadable(lazy(() => import('./NewDealership/NewDealership')));

const dealershipRoutes = [
  { path: '/dealership/dealerships', element: <Dealerships />, auth: authRoles.Dealer },
  { path: '/dealership/dealership/:id', element: <Dealership />, auth: authRoles.Admin },
  { path: '/dealership/new', element: <NewDealership />, auth: authRoles.Admin },
];

export default dealershipRoutes;
