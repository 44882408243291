import axios from 'axios.js';

export const GET_TEACHER = 'GET_TEACHER';
export const POST_TEACHER = 'POST_TEACHER';
export const TEACHER_SUCCEEDED = 'TEACHER_SUCCEEDED';
export const TEAHCER_ERRORS = 'TEACHER_ERRORS';

export const postTeacher= (data) => async (dispatch) => {
  try {
    const res = await axios.post(`/Teacher`, { ...data });
    
    dispatch({
      type: POST_TEACHER,
      payload: res.data.succeeded,
    })
  } catch (e) {
    dispatch({
      type: TEAHCER_ERRORS,
      payload: e,
    })
  }
};

export const resetTeacherSucceeded = () => (dispatch) => {
  dispatch({
    type: TEACHER_SUCCEEDED,
    payload: null,
  })
};

export const resetTeacherErrors = () => (dispatch) => {
  dispatch({
    type: TEAHCER_ERRORS,
    payload: null,
  })
};