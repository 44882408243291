import axios from 'axios';
import useAuth from 'app/hooks/useAuth';

const { REACT_APP_API_HOST } = process.env;

const axiosInstance = axios.create({
  baseURL: `${REACT_APP_API_HOST}/api`
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      const { logout, user } = useAuth();
      console.log('Failed to login')
      logout();
    }
    return Promise.reject((error.response && error.response.data && error.response.data.errors && error.response.data.errors.join(', ')) || 'Something went wrong!')
  }
);

export default axiosInstance;
