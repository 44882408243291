import {
  GET_DEALERSHIP,
  POST_DEALERSHIP,
  DEALERSHIP_ERRORS,
  DEALERSHIP_SUCCEEDED,
} from '../actions/DealershipActions';

const initialState = {
  dealershipSucceeded: null,
  dealershipErrors: null,
  dealership: {
    companyTitle: "",
    isApproved: false,
    fullName: "",
    phoneNumber: "",
    email: "",
    created: "",
    id: 0,
    balance: 0,
    city: "",
  }
};

const DealershipReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_DEALERSHIP: {
      return {
        ...state,
        dealership: action.payload,
      };
    }
    case POST_DEALERSHIP: {
      return {
        ...state,
        dealershipSucceeded: action.payload
      };
    }
    case DEALERSHIP_SUCCEEDED: {
      return {
        ...state,
        dealershipSucceeded: action.payload
      };
    }
    case DEALERSHIP_ERRORS: {
      return {
        ...state,
        dealershipErrors: action.payload
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default DealershipReducer;