import { Button, Icon, styled } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const StyledIconButton = ({ icon, text, color, onClick }) => {
  return (
    <StyledButton variant="contained" color={color} onClick={onClick}>
      <Icon sx={{ mr: 1 }}>{icon}</Icon>
      {text}
    </StyledButton>
  );
};

export default StyledIconButton;
