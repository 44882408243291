import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Computers = Loadable(lazy(() => import('./Computers')));

const computerRoutes = [
  { path: '/computer/computers', element: <Computers />, auth: authRoles.Teacher },
];

export default computerRoutes;
