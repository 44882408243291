import { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Icon,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import axios from "axios.js";
import { StyledIconButton } from "app/components";
import { useSnackbar } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { sxOptions } from "./DatatableStyleComponent";

const Datatable = ({
  title = null,
  columns,
  path,
  navigatePath = null,
  refresh,
  columnButtonSettings = null,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [data, setData] = useState([["Yükleniyor..."]]);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const initialDialogModel = {
    title: "",
    isModalOpen: false,
    url: null,
    params: null,
    method: null,
  };
  const [dialogModel, setDialogModel] = useState(initialDialogModel);
  const [dialogInput, setDialogInput] = useState("");
  const [tableRowIndex, setTableRowIndex] = useState(null);
  const [tableRowIndexes, setTableRowIndexes] = useState([]);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAllMenuClick = (event) => {
    setTableRowIndex(null);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    getData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  const getData = async (page) => {
    setIsLoading(true);
    const res = await xhrRequest(page);
    setData(res.data);
    setIsLoading(false);
    setCount(res.total);
  };

  const sort = () => {
    setIsLoading(true);
    xhrRequest(page, sortOrder).then((res) => {
      setData(res.data);
      setPage(res.page);
      setSortOrder(sortOrder);
      setIsLoading(false);
      setCount(res.total);
    });
  };

  const xhrRequest = async (pageNumber) => {
    let res = await axios.get(
      `${path}PageNumber=${pageNumber}&PageSize=${rowsPerPage}`
    );
    let fullData = res.data.result.items;
    const total = res.data.result.totalCount;

    let sortField = sortOrder.name;
    let sortDir = sortOrder.direction;

    if (sortField) {
      fullData = fullData.sort((a, b) => {
        if (a[sortField] < b[sortField]) {
          return 1 * (sortDir === "asc" ? -1 : 1);
        } else if (a[sortField] > b[sortField]) {
          return -1 * (sortDir === "asc" ? -1 : 1);
        } else {
          return 0;
        }
      });
    }
    let data = fullData;

    return { data, total, page: pageNumber };
  };

  const changePage = (tablePageNumber, tableSortOrder) => {
    setIsLoading(true);
    xhrRequest(tablePageNumber + 1).then((res) => {
      setPage(res.page);
      setData(res.data);
      setSortOrder(tableSortOrder);
      setIsLoading(false);
      setCount(res.total);
    });
  };

  const themePointer = createTheme({
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.MuiTableRow-hover:hover": {
              cursor: "pointer",
            },
          },
        },
      },
    },
  });

  const _handleFileDownload = (name, description) => {
    const element = document.createElement("a");
    const file = new Blob([description], {
      type: "application/octet-stream",
    });
    element.href = URL.createObjectURL(file);
    element.download = name;
    document.body.appendChild(element);
    element.click();
  };

  const buttonRequests = async (url, method, params = null) => {
    await axios({ url, data: { ...params }, method })
      .then((res) => {
        const variant = "success";
        enqueueSnackbar("Başarılı", { variant, autoHideDuration: 1000 });
        getData(page);
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err, { variant });
      });
  };

  const modalButtonRequest = async (url, method, params, content) => {
    if (params && params.payload) params.payload = content;
    url = url.includes("/labelName/")
      ? url.replaceAll("/labelName/", content)
      : url;

    buttonRequests(url, method, params);
    setDialogModel(initialDialogModel);
    setDialogInput("");
  };

  const columnButtons = () => {
    if (columnButtonSettings == null) return null;

    if (columnButtonSettings.type === "Button") {
      return {
        name: columnButtonSettings.name,
        label: columnButtonSettings.label,
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <>
                {columnButtonSettings.buttons.map((x, i) => {
                  if (x.type === "download") {
                    return (
                      <Button
                        key={i}
                        onClick={() =>
                          _handleFileDownload(
                            x.filename,
                            data[dataIndex].userGuid
                          )
                        }
                      >
                        <Icon sx={{ mr: 1 }}>{x.icon}</Icon>
                      </Button>
                    );
                  } else if (x.type === "password") {
                    return (
                      <Button
                        key={i}
                        onClick={() => {
                          setDialogModel({
                            method: "POST",
                            title: "Şifre Değiştir",
                            isModalOpen: true,
                            url: x.path,
                            params: JSON.parse(
                              JSON.stringify(x.params).replace(
                                "/uuid/",
                                data[dataIndex].userGuid
                              )
                            ),
                          });
                        }}
                      >
                        <Icon sx={{ mr: 1 }}>{x.icon}</Icon>
                      </Button>
                    );
                  } else if (x.type === "delete") {
                    return (
                      <Button
                        key={i}
                        onClick={() =>
                          buttonRequests(
                            x.path.includes("/uuid/")
                              ? x.path.replaceAll(
                                "/uuid/",
                                data[dataIndex].userGuid
                              )
                              : x.path,
                            x.method,
                            x.params
                              ? JSON.parse(
                                JSON.stringify(x.params)
                                  .replaceAll(
                                    "/uuid/",
                                    data[dataIndex].userGuid
                                  )
                                  .replaceAll(
                                    "/roleId/",
                                    data[dataIndex].isManager ? "1" : "3"
                                  )
                              )
                              : null
                )
                        }
                      >
                <Icon sx={{ mr: 1 }}>{x.icon2 ? data[dataIndex].isManager ? x.icon : x.icon2 : x.icon}</Icon>
              </Button>
            );
          } else
                    return(
                      <StyledIconButton
                        key = { i }
                        text = { x.text }
                        icon = { x.icon }
                        color = { x.color }
                        onClick = {() =>
buttonRequests(
  x.path.replace("/ColumnId/", data[dataIndex].id),
  "PUT"
)
                        }
/>
                    );
                })}
              </>
            );
          },
        },
      };
    } else if (columnButtonSettings.type === "Actions") {
  return {
    name: columnButtonSettings.name,
    label: columnButtonSettings.label,
    options: {
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Actions">
                <IconButton
                  onClick={handleMenuClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Icon>{columnButtonSettings.button.icon}</Icon>
                </IconButton>
              </Tooltip>
            </Box>
            {tableRowIndex == rowIndex ? (
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                PaperProps={{
                  elevation: 0,
                  sx: sxOptions,
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {columnButtonSettings.button.actions.map((x, i) => {
                  if (x.action === "modal") {
                    return (
                      <MenuItem
                        key={i}
                        onClick={() => {
                          setDialogModel({
                            title: x.name,
                            isModalOpen: true,
                            method: x.method,
                            url: x.path.includes("/ColumnId/")
                              ? x.path.replaceAll(
                                "/ColumnId/",
                                data[tableRowIndex].id
                              )
                              : x.path,
                            params: x.params
                              ? JSON.parse(
                                JSON.stringify(x.params).replaceAll(
                                  "/uuid/",
                                  data[tableRowIndex].uuid
                                )
                              )
                              : null,
                          });
                        }}
                      >
                        {" "}
                        <Icon>{x.icon}</Icon> {x.name}{" "}
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem
                        key={i}
                        onClick={() =>
                          buttonRequests(
                            x.path.includes("/ColumnId/")
                              ? x.path.replaceAll(
                                "/ColumnId/",
                                data[tableRowIndex].id
                              )
                              : x.path,
                            x.method,
                            x.params
                              ? JSON.parse(
                                JSON.stringify(x.params)
                                  .replaceAll(
                                    "/uuid/",
                                    data[tableRowIndex].uuid
                                  )
                                  .replaceAll(
                                    "/ColumnId/",
                                    data[tableRowIndex].id
                                  )
                              )
                              : null
                          )
                        }
                      >
                        <Icon>{x.icon}</Icon> {x.name}
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  };
}
  };

const theme = createTheme();

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "vertical",
  serverSide: true,
  count: count,
  rowsPerPage: rowsPerPage,
  rowsPerPageOptions: [10, 25, 50],
  sortOrder: sortOrder,
  //selectableRowsHideCheckboxes: true,
  selectableRows:
    columnButtonSettings && columnButtonSettings.groupButtons
      ? "multiple"
      : "none",
  customToolbarSelect: () => (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Tooltip title="Actions">
          <IconButton
            onClick={handleAllMenuClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Icon>{columnButtonSettings.button.icon}</Icon>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: sxOptions,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {columnButtonSettings.groupButtons.actions.map((x, i) => {
          if (x.action === "modal") {
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  setDialogModel({
                    title: x.name,
                    isModalOpen: true,
                    method: x.method,
                    url: x.path,
                    params: x.params
                      ? JSON.parse(
                        JSON.stringify(x.params).replaceAll(
                          '"/uuids/"',
                          JSON.stringify(tableRowIndexes)
                        )
                      )
                      : null,
                  });
                }}
              >
                {" "}
                <Icon>{x.icon}</Icon> {x.name}{" "}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  buttonRequests(
                    x.path,
                    x.method,
                    x.params
                      ? JSON.parse(
                        JSON.stringify(x.params).replaceAll(
                          '"/uuids/"',
                          JSON.stringify(tableRowIndexes)
                        )
                      )
                      : null
                  );
                }}
              >
                <Icon>{x.icon}</Icon> {x.name}
              </MenuItem>
            );
          }
        })}
      </Menu>
    </>
  ),
  onCellClick: (cellIndex, rowIndex, dataIndex) => {
    setTableRowIndex(rowIndex.dataIndex);
    if (navigatePath) navigate(navigatePath + data[rowIndex.dataIndex].id);
  },
  onTableChange: (action, tableState) => {
    switch (action) {
      case "changePage":
        changePage(tableState.page, tableState.sortOrder);
        break;
      case "sort":
        sort(tableState.page, tableState.sortOrder);
        break;
      case "changeRowsPerPage":
        setRowsPerPage(tableState.rowsPerPage);
        break;
      default:
        break;
    }
  },
  onRowSelectionChange: (currentSelect, allSelected) => {
    let tmpSelectedRowIndexes = [];

    allSelected.map((x) => x.dataIndex);
    tmpSelectedRowIndexes = allSelected.map((x) => data[x.dataIndex]);

    setTableRowIndexes(tmpSelectedRowIndexes.map((q) => q.uuid));
  },
};

return (
  <Box width="100%" overflow="auto">
    <div>
      <Dialog
        open={dialogModel.isModalOpen}
        onClose={() => {
          setDialogModel(initialDialogModel);
        }}
      >
        <DialogTitle>{dialogModel.title}</DialogTitle>
        <DialogContent sx={{ minWidth: 500 }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            value={dialogInput}
            onChange={(e) => setDialogInput(e.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              modalButtonRequest(
                dialogModel.url,
                dialogModel.method,
                dialogModel.params,
                dialogInput
              )
            }
          >
            Gönder
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    <ThemeProvider theme={navigatePath ? themePointer : theme}>
      <MUIDataTable
        title={
          title ? (
            <Typography variant="h6">
              {title}
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </Typography>
          ) : null
        }
        data={data}
        columns={
          columnButtonSettings ? [...columns, columnButtons()] : columns
        }
        options={options}
      />
    </ThemeProvider>
  </Box>
);
};

export default Datatable;
