import axios from 'axios.js';

export const GET_CORPORATION = 'GET_CORPORATION';
export const POST_CORPORATION = 'POST_CORPORATION';
export const CORPORATION_SUCCEEDED = 'CORPORATION_SUCCEEDED';
export const CORPORATION_ERRORS = 'CORPORATION_ERRORS';
export const GET_CORPORATIONSETTING ='GET_CORPORATIONSETTING';
export const UPDATE_CORPORATIONSETTING = 'UPDATE_CORPORATIONSETTING';

export const getCorporation = (id) => async (dispatch) => {
  const res = await axios.get(`/Corporation/${id}`);
  dispatch({
    type: GET_CORPORATION,
    payload: res.data.result,
  });
};

export const getCorporationSetting = (id) => async (dispatch) => {

  const res = await axios.get(`/Corporation/corporation-settings/${id}`);
  dispatch({
    type: GET_CORPORATIONSETTING,
    payload: res.data.result,
  });
};

export const putCorporationSetting = (values) => async (dispatch) => {
  const res = await axios.put(`/Corporation/${values.corporationId}`,values);
  dispatch({
    type: UPDATE_CORPORATIONSETTING,
    payload: res.data.result,
  });
};


export const postCorporation= (data) => async (dispatch) => {
  try {
    const res = await axios.post(`/Corporation`, { ...data });
    dispatch({
      type: POST_CORPORATION,
      payload: res.data.succeeded,
    })
  } catch (e) {
    dispatch({
      type: CORPORATION_ERRORS,
      payload: e,
    })
  }
};


export const resetCorporationSucceeded = () => (dispatch) => {
  dispatch({
    type: CORPORATION_SUCCEEDED,
    payload: null,
  })
};

export const resetCorporationErrors = () => (dispatch) => {
  dispatch({
    type: CORPORATION_ERRORS,
    payload: null,
  })
};
