import {
  GET_TEACHER,
  TEAHCER_ERRORS,
  TEACHER_SUCCEEDED,
  POST_TEACHER
} from '../actions/TeacherActions';

const initialState = {
  teacherSucceeded: null,
  teacherErrors: null,
  teacher: {
    id: 0,
    fullName: "",
    userName: "",
    email: "",
    emailConfirmed: false,
    lastActive: "",
    phoneNumber: "",
    phoneConfirmed: false,
    userRoles: [],
    dealerships: [],
  },
};

const TeacherReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_TEACHER: {
      return {
        ...state,
        teacher: action.payload,
      };
    }
    case POST_TEACHER:{
      return {
        ...state,
        teacherSucceeded: action.payload
      };
    }
    case TEACHER_SUCCEEDED: {
      return {
        ...state,
        teacherSucceeded: action.payload
      };
    }
    case TEAHCER_ERRORS: {
      return {
        ...state,
        teacherErrors: action.payload
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default TeacherReducer;