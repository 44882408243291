import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Corporations = Loadable(lazy(() => import('./Corporations')));
const Corporation = Loadable(lazy(() => import('./Corporation')));

const corporationRoutes = [
  { path: '/corporation/corporations', element: <Corporations />, auth: authRoles.Teacher },
  { path: '/corporation/corporation/:id', element: <Corporation />, auth: authRoles.Teacher },
];

export default corporationRoutes;
