import { Box, Card, Grid, Icon, Button, styled } from '@mui/material';
import { Small } from 'app/components/Typography';

const StyledCard = styled(Card)(({ theme }) => ({
  textAlign: 'start',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main },
}));

const Heading = styled('h6')(({ theme }) => ({
  margin: 0,
  marginTop: '4px',
  fontSize: '14px',
  fontWeight: '500',
  color: theme.palette.primary.main,
}));

const StatCard = ({ icon, title, description, onClick }) => {
  return (
    <Button onClick={onClick} fullWidth>
      <Grid item xs={12} md={12}>
        <StyledCard elevation={6}>
          <ContentBox>
            <Icon className="icon">{icon}</Icon>
            <Box ml="12px">
              <Small>{title}</Small>
              <Heading>{description}</Heading>
            </Box>
          </ContentBox>

          {/* <Tooltip title="View Details" placement="top">
              <IconButton>
                <Icon>arrow_right_alt</Icon>
              </IconButton>
            </Tooltip> */}
        </StyledCard>
      </Grid>
    </Button>
  );
};

export default StatCard;
