import {
  GET_CORPORATION,
  POST_CORPORATION,
  CORPORATION_SUCCEEDED,
  CORPORATION_ERRORS,
  GET_CORPORATIONSETTING,
  UPDATE_CORPORATIONSETTING,
} from '../actions/CorporationActions';



const initialState = {
  corporationSucceeded: null,
  corporationErrors: null,
  corporation: {
    id: 0,
    corporationName: "",
    corporationLicence: "",
    corporationEmail: "",
    corporationAddress: "",
    corporationAuthorizedPerson: "",
    corporationOfficer: "",
    numberOfTeacher: 0,
    unUsedKeyCount: 0,
    date: ""
  }
};

const CorporationReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_CORPORATION: {
      return {
        ...state,
        corporation: action.payload,
      };
    }
    case GET_CORPORATIONSETTING: {
      return {
        ...state,
        corporationSetting: action.payload,
      };
    }
    case POST_CORPORATION: {
      return {
        ...state,
        corporationSucceeded: action.payload
      };
    }
    case UPDATE_CORPORATIONSETTING: {
      return {
        ...state,
        corporationUpdate: action.payload
      };
    }
    case CORPORATION_SUCCEEDED: {
      return {
        ...state,
        corporationSucceeded: action.payload
      };
    }
    case CORPORATION_ERRORS: {
      return {
        ...state,
        corporationErrors: action.payload
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default CorporationReducer;