import AuthGuard from 'app/auth/AuthGuard';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import dealershipRoutes from 'app/views/dealership/DealershipRoutes';
import corporationRoutes from 'app/views/corporation/CorporationRoutes';
import teacherRoutes from 'app/views/teacher/TeacherRoutes';
import computerRoutes from 'app/views/computer/ComputerRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...dashboardRoutes, ...dealershipRoutes, ...corporationRoutes, ...teacherRoutes, ...computerRoutes], //...chartsRoute, ...materialRoutes, ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
