import {
  POST_PAYMENT,
  PAYMENT_ERRORS,
  PAYMENT_SUCCEEDED,
} from '../actions/PaymentActions';

const initialState = {
  paymentSucceeded: null,
  paymentErrors: null,
};

const PaymentReducer = function (state = initialState, action) {
  switch (action.type) {
    case POST_PAYMENT: {
      return {
        ...state,
        paymentSucceeded: action.payload
      };
    }
    case PAYMENT_SUCCEEDED: {
      return {
        ...state,
        paymentSucceeded: action.payload
      };
    }
    case PAYMENT_ERRORS: {
      return {
        ...state,
        paymentErrors: action.payload
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default PaymentReducer;