import { Box, Button, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  FlexBox,
  JustifyBox,
  IMG,
  NotFoundRoot,
} from "./NotFoundStyleComponent";
const NotFound = () => {
  const navigate = useNavigate();

  return (
    <NotFoundRoot>
      <JustifyBox>
        <IMG src="/assets/images/illustrations/404.svg" alt="" />

        <Button
          color="primary"
          variant="contained"
          sx={{ textTransform: "capitalize" }}
          onClick={() => navigate(-1)}
        >
          Geri Dön
        </Button>
      </JustifyBox>
    </NotFoundRoot>
  );
};

export default NotFound;
