import axios from 'axios.js';

export const GET_DEALERSHIP = 'GET_DEALERSHIP';
export const POST_DEALERSHIP = 'POST_DEALERSHIP';
export const DEALERSHIP_SUCCEEDED = 'DEALERSHIP_SUCCEEDED';
export const DEALERSHIP_ERRORS = 'DEALERSHIP_ERRORS';

export const getDealership = (id) => async (dispatch) => {
  const res = await axios.get(`/Dealership/${id}`);
  
  dispatch({
    type: GET_DEALERSHIP,
    payload: res.data.result,
  });
};



export const postDealership = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`/Dealership`, { ...data });
    dispatch({
      type: POST_DEALERSHIP,
      payload: res.data.succeeded,
    })
  } catch (e) {
    dispatch({
      type: DEALERSHIP_ERRORS,
      payload: e,
    })
  }
};

export const resetDealershipSucceeded = () => (dispatch) => {
  dispatch({
    type: DEALERSHIP_SUCCEEDED,
    payload: null,
  })
};

export const resetDealershipErrors = () => (dispatch) => {
  dispatch({
    type: DEALERSHIP_ERRORS,
    payload: null,
  })
};


