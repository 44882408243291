import { combineReducers } from 'redux';
import EcommerceReducer from './EcommerceReducer';
import NavigationReducer from './NavigationReducer';
import NotificationReducer from './NotificationReducer';
import LocationReducer from './LocationReducer';
import DealershipReducer from './DealershipReducer';
import CorporationReducer from './CorporationReducer';
import PaymentReducer from './PaymentReducer';
import TeacherReducer from './TeacherReducer';

const RootReducer = combineReducers({
  notifications: NotificationReducer,
  navigations: NavigationReducer,
  ecommerce: EcommerceReducer,
  location: LocationReducer,
  dealership: DealershipReducer,
  corporation: CorporationReducer,
  payment: PaymentReducer,
  teacher: TeacherReducer
});

export default RootReducer;
