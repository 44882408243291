import axios from 'axios.js';

export const POST_PAYMENT = 'POST_PAYMENT';
export const PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED';
export const PAYMENT_ERRORS = 'PAYMENT_ERRORS';

export const postPayment = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`/Payment`, { ...data });
    dispatch({
      type: POST_PAYMENT,
      payload: res.data.succeeded,
    })
  } catch (e) {
    dispatch({
      type: PAYMENT_ERRORS,
      payload: e,
    })
  }
};

export const resetPaymentSucceeded = () => (dispatch) => {
  dispatch({
    type: PAYMENT_SUCCEEDED,
    payload: null,
  })
};

export const resetPaymentErrors = () => (dispatch) => {
  dispatch({
    type: PAYMENT_ERRORS,
    payload: null,
  })
};


