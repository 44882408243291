import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Teachers = Loadable(lazy(() => import('./Teachers')));

const teacherRoutes = [
  { path: '/teacher/teachers', element: <Teachers />, auth: authRoles.Teacher },
];

export default teacherRoutes;
